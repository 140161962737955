import Link from 'next/link';
import React, {useEffect} from 'react';
import {useState} from 'react';
import {AiTwotoneLock} from 'react-icons/ai';
import {Button} from './button';

export function CookieBanner() {
  const [isOpen, setIsOpen] = useState(false);

  function closeModal() {
    localStorage.setItem('cookies_enabled', '1');
    setIsOpen(false);
  }

  function openModal() {
    setIsOpen(true);
  }

  useEffect(() => {
    if (localStorage.getItem('cookies_enabled') === null) {
      openModal();
    }
  }, []);

  if (!isOpen) return null;

  return (
    <div className="fixed z-10 max-w-xl right-4 bottom-4 left-4 lg:bottom-8 lg:left-8">
      <div className="inline-block w-full p-6 overflow-hidden text-left transition-all transform border shadow-lg lg:p-8 bg-gray-50 dark:bg-background rounded-xl dark:border">
        <h3 className="flex items-center text-lg font-semibold leading-6 text-foreground">
          Din integritet <AiTwotoneLock className="ml-1 text-blue-600" size={14} />
        </h3>
        <div className="mt-2">
          <p className="text-sm text-dark-gray">
            Vi använder cookies och liknande teknologier för att hjälpa till att anpassa innehåll,
            skräddarsy och mäta annonser och tillhandahålla en bättre upplevelse. Genom att klicka
            på OK godkänner du detta enligt{' '}
            <Link href="/cookies">
              <a>vår policy för cookies</a>
            </Link>
            .
          </p>
        </div>

        <div className="mt-6">
          <Button variant="secondary" onClick={() => closeModal()} label="OK, jag förstår!" />
        </div>
      </div>
    </div>
  );
}
