import cn from 'classnames';

type SectionProps = {
  variant?: 'sm' | 'md' | 'lg' | 'xl' | '2xl' | 'full';
  children: React.ReactNode;
  className?: string;
};

export function Section({variant = 'xl', className, ...props}: SectionProps) {
  const styles = cn(
    'mx-auto w-full relative py-6 md:py-10',
    variant === 'sm' && 'max-w-screen-sm',
    variant === 'md' && 'max-w-screen-md',
    variant === 'lg' && 'max-w-screen-lg',
    variant === 'xl' && 'max-w-screen-xl',
    variant === '2xl' && 'max-w-screen-2xl',
    variant === 'full' ? 'max-w-screen-full' : 'container',
    className
  );

  return <section className={styles} {...props}></section>;
}
