import React from 'react';
import Link from 'next/link';
import cn from 'classnames';

import {stripHtml, trimLength} from '@/lib/utils';
import {CompanyAvatar} from './company-avatar';
import {ArticleCategories} from './article-categories';
import {ArticleSkeleton} from './article-skeleton';
import {DateFormatter} from '../date-formatter';

import type {ArticleType} from '../../@types/article';
import {ArticleImage} from './article-image';

export type ArticleProps = {
  article: ArticleType | null;
  variant?: 'default' | 'main' | 'horizontal' | 'large' | 'small';
  showAvatar?: boolean;
  showDate?: boolean;
  className?: string;
  priority?: boolean;
};

export function Article({
  article,
  variant = 'horizontal',
  className,
  showAvatar,
  showDate,
  priority,
}: ArticleProps) {
  if (!article) return <ArticleSkeleton variant={variant} />;
  const {body, excerpt} = article;
  const isSmall = variant === 'small';
  const isHorizontal = variant === 'horizontal' || isSmall;
  const isLarge = variant === 'large';
  const content = stripHtml(
    isLarge ? excerpt || trimLength(body, 850) : trimLength(excerpt || body, 250)
  );
  const shouldShowDate = showDate ?? variant === 'horizontal';
  const showContent = content && (variant === 'horizontal' || variant === 'main');
  const tags = article?.tags?.slice(0, 3);
  const categories = tags?.length ? [] : article?.categories?.slice(0, 1);
  const date = article.date || article.published_at || article.created_at;

  return (
    <article
      className={cn(
        'flex',
        isHorizontal ? 'flex-row-reverse py-4 items-center min-w-0' : 'flex-col',
        className
      )}
    >
      <ArticleImage article={article} variant={variant} priority={priority} />

      {/* Text (min-w-0 is important to not let the text overflow in flexbox) */}
      <div className={cn('flex-1 min-w-0', !isHorizontal && 'pt-4')}>
        <div className="mb-1 text-xs">
          <ArticleCategories tags={tags} categories={categories} />
        </div>

        {/* Title */}
        <Link href={`/nyheter/${article.slug}`}>
          <a className="text-foreground">
            <h2
              className={cn(
                `font-bold`,
                variant === 'main' ? 'sm:text-4xl lg:text-4xl' : 'text-xl',
                variant === 'default' ? 'line-clamp-3' : 'line-clamp-2'
              )}
              style={{lineHeight: '1.26em'}}
            >
              {article.title}
            </h2>
          </a>
        </Link>

        {/* Content */}
        {showContent && (
          <div className={`my-2 text-sm md:text-md  hidden sm:block ${isHorizontal ? 'pr-8' : ''}`}>
            <p className={cn(variant === 'main' ? 'line-clamp-2' : 'line-clamp-1')}>{content}</p>
          </div>
        )}

        {/* Avatar and Date */}
        <div className="flex items-center text-xs text-dark-gray">
          {showAvatar && (
            <>
              <CompanyAvatar companies={article.companies} source={article.source} />
              <svg width="2" height="2" fill="currentColor" className="mx-1.5" aria-hidden="true">
                <circle cx="1" cy="1" r="1" />
              </svg>
            </>
          )}
          {shouldShowDate && <DateFormatter dateString={date} variant={'distance'} />}
        </div>
      </div>
    </article>
  );
}
