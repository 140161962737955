import {ArticleType} from '@/types/article';
import Link from 'next/link';
import React from 'react';

type ArticleCategories = {
  categories?: ArticleType['categories'];
  tags?: ArticleType['tags'];
};

export function ArticleCategories({categories = [], tags = []}: ArticleCategories) {
  const categoriesList = categories.slice(0, 2);
  const tagsList = tags.slice(0, 3 - categoriesList.length);
  const leftovers = categories.length + tags.length - categoriesList.length - tagsList.length;
  return (
    <div className="flex min-w-0">
      <div className="truncate">
        {categoriesList.map((c) => (
          <Link key={c.id} href={`/kategori/${c.slug}`}>
            <a className="mr-1.5 font-bold uppercase">{c.title}</a>
          </Link>
        ))}
        {tagsList.map((t) => (
          <Link key={t.id} href={`/tag/${t.slug}`}>
            <a className="mr-1 font-bold uppercase">{t.title}</a>
          </Link>
        ))}
      </div>
      {leftovers > 0 && (
        <span className="px-1 text-[9px] font-bold rounded text-primary badge">+{leftovers}</span>
      )}
    </div>
  );
}
