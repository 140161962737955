import Image from 'next/image';
import cn from 'classnames';
import {ArticleType} from '@/types/article';
import {Company} from '@/types/company';
import Link from 'next/link';
import React from 'react';
import {capitalizeFirst} from '@/lib/utils';

const sourceLogos = {
  cision: '/static/images/partners/cision.svg',
  direkt: '/static/images/partners/direkt.png',
  finwire: '/static/images/partners/finwire.png',
  mfn: '/static/images/partners/mfn.png',
  'ipo.se': '/static/favicons/logo.svg',
  default: '/static/favicons/logo.svg',
};

type ImgProps = {
  size?: 'lg';
  className?: string;
  children: React.ReactNode;
};

function ImgContainer({size, className, ...props}: ImgProps) {
  return (
    <div
      className={cn(
        'flex rounded-md items-center overflow-hidden bg-white shrink-0 w-full shadow dark:border-background',
        size === 'lg' ? 'w-9 h-9 p-0.5 rounded-full mr-1.5 dark:border-2' : 'w-5 h-5 p-px mr-1',
        className
      )}
      {...props}
    />
  );
}

type CompanyAvatarProps = {
  showAvatar?: boolean;
  size?: 'lg';
  source?: ArticleType['source'];
  companies?: Company[];
};

export function CompanyAvatar({
  source = 'ipo.se',
  showAvatar = true,
  companies,
  size,
}: CompanyAvatarProps) {
  const avatarSource = source ? sourceLogos[source] : sourceLogos.default;
  const isIpoSe = source === 'ipo.se' || !source;

  return (
    <div
      className={cn(
        'flex items-center min-w-0 text-dark-gray',
        size === 'lg' ? 'text-sm' : 'text-xs'
      )}
    >
      {showAvatar && (
        <ImgContainer
          size={size}
          className={cn(isIpoSe ? (size === 'lg' ? 'pb-1.5 pl-1.5 pr-1' : 'pl-0.5 pb-0.5') : '')}
        >
          <Image
            src={avatarSource}
            alt={`${source} logo`}
            width={isIpoSe ? '50%' : '100%'}
            height={isIpoSe ? '50%' : '100%'}
            objectFit="contain"
          />
        </ImgContainer>
      )}
      {!companies?.length && capitalizeFirst(source || 'IPO.se')}

      {/* Company FIXME: DEAL WITH MULTIPLE COMPANIES */}
      {companies?.slice(0, 1)?.map((company, i) => {
        return (
          <React.Fragment key={i}>
            {/* Company Logo */}
            {showAvatar && company.logo && (
              <ImgContainer
                size={size}
                className={`z-10 ${size === 'lg' ? '-ml-3' : '-ml-1.5 sm:ml-[-7px]'}`}
              >
                <Image
                  src={company.logo.url}
                  width={company.logo.width}
                  height={company.logo.height}
                  alt={`${company.name} logo`}
                />
              </ImgContainer>
            )}

            {/* Company Name */}
            <Link href={`/search?s=${company.name}`}>
              <a className="truncate text-dark-gray">{company?.name}</a>
            </Link>
          </React.Fragment>
        );
      })}
    </div>
  );
}
