import * as dateFns from 'date-fns';
import {sv} from 'date-fns/locale';

import {swedishWordToNumbers} from '@/lib/utils';

type StringVariants =
  | 'default'
  | 'short'
  | 'long'
  | 'longTime'
  | 'distance'
  | 'shortDistance'
  | 'calendarDays'
  | 'time';

/**
 * Helper function to format dates
 * @param dateString - The date as a string.
 * @param variant - Variant
 * @param formatOverride - Optional override format settings
 * @returns a formatted date string.
 */
function formatDate(dateString = '', variant: StringVariants, formatOverride?: string) {
  if (!dateString) return null;
  const dateObj = dateFns.parseISO(dateString);

  let formatString;
  let date;
  if (variant === 'shortDistance' && (dateFns.isToday(dateObj) || dateFns.isYesterday(dateObj))) {
    date = dateFns.formatDistanceToNowStrict(dateObj, {
      locale: sv,
      addSuffix: true,
    });

    date = swedishWordToNumbers(date);
    date = date.replace(' minuter', 'm').replace(' timmar', 'h');
  } else if (variant === 'distance' && (dateFns.isToday(dateObj) || dateFns.isYesterday(dateObj))) {
    date = dateFns.formatDistanceToNowStrict(dateObj, {
      locale: sv,
      addSuffix: true,
    });
    date = swedishWordToNumbers(date);
  } else {
    switch (variant) {
      case 'default':
        formatString = 'd MMMM'; // 5 januari
        break;
      case 'short':
        formatString = 'd MMM'; // 5 jan.
        break;
      case 'long':
        formatString = 'd MMMM yyyy'; // 5 januari 2020
        break;
      case 'longTime':
        formatString = 'd MMMM yyyy, H:mm'; // 5 januari 2020, 13:00
        break;
      case 'time':
        formatString = 'H:mm'; // 13:00
        break;
      default:
        formatString = 'd MMM yyyy'; // 5 jan
    }

    date = dateFns.format(dateObj, formatOverride || formatString, {
      locale: sv,
    });
  }

  // Remove '.' from date if shortened
  if (date.charAt(date.length - 1) === '.') {
    date = date.slice(0, -1);
  }

  return date;
}

type DateFormatterProps = {
  dateString: string;
  variant?: StringVariants;
  formatOverride?: string;
};

// by providing a default string of 'PP' or any of its variants for `formatStr`
// it will format dates in whichever way is appropriate to the locale
export function DateFormatter({
  dateString = '',
  variant = 'default',
  formatOverride,
}: DateFormatterProps) {
  return (
    <time dateTime={dateString} className="shrink-0">
      {formatDate(dateString, variant, formatOverride)}
    </time>
  );
}
