import Skeleton from '@mui/material/Skeleton';
import cn from 'classnames';

export function ArticleSkeleton({variant, main}: {main?: boolean; variant?: string}) {
  const isHorizontal = variant === 'horizontal' || variant === 'small';
  const isFeatured = variant === 'featured';
  if (variant === 'small')
    return (
      <article className="flex flex-row-reverse items-center min-w-0 py-4 animate-pulse">
        <div className="p-2 ml-6 overflow-hidden rounded-lg bg-background-hover">
          {/* Image */}
          <div className="relative block w-14 h-14" />
        </div>
        <div className="flex-1 min-w-0">
          <div className="flex w-24 h-3 min-w-0 mb-3 rounded-sm bg-background-hover" />
          <div className="flex w-full h-4 min-w-0 mb-2 rounded-sm bg-background-hover" />
          <div className="flex w-[90%] h-4 min-w-0 rounded-sm bg-background-hover" />
          <div className="flex items-center text-xs text-dark-gray"></div>
        </div>
      </article>
    );
  return (
    <article
      className={cn(
        `flex flex-col items-center relative w-full`,
        isHorizontal && 'flex-row-reverse'
      )}
    >
      {/* Image */}
      <div className={`relative w-full ${isHorizontal && 'w-1/3'}`}>
        <div
          className={`relative w-full ${
            isHorizontal ? 'pb-[75%]' : main ? 'pb-[55.7%]' : 'pb-[100%] md:pb-[55%]'
          }`}
        >
          <Skeleton
            width={'100%'}
            height={'100%'}
            className="absolute inset-0"
            style={{transform: 'scale(1, 1)'}}
          />
        </div>
      </div>
      {/* Text */}
      <div
        className={cn(
          `w-full`,
          isHorizontal ? 'pr-6' : 'mt-2',
          isFeatured && 'absolute px-8 pl-4 bottom-6'
        )}
      >
        <div className="mt-3">
          <Skeleton variant="text">
            <p
              className={cn(
                `bold uppercase inline-block`,
                isHorizontal ? 'text-sm' : 'text-xs mt-1',
                !isFeatured ? `text-primary` : 'text-white bg-primary px-1 text-[12px]'
              )}
            >
              Category
            </p>
          </Skeleton>
          <Skeleton variant="text" width={'100%'}>
            <h2 className={`text-lg ${main && 'lg:text-2xl'}  font-bold`}>s</h2>
          </Skeleton>
          <Skeleton variant="text" width={'100%'}>
            <h2 className="text-lg font-bold">s</h2>
          </Skeleton>
          <Skeleton variant="text" width={'40%'}>
            <span>Text</span>
          </Skeleton>
        </div>

        {isHorizontal && (
          <div className="flex items-center mt-2">
            <Skeleton variant="circular" width={25} height={25} className="mr-2" />
            <div className="w-1/2">
              <Skeleton variant="text" height={15} width={'100%'} />
              <Skeleton variant="text" height={15} width={'100%'} />
            </div>
          </div>
        )}
      </div>
    </article>
  );
}
