import {getImageUrl} from '@/lib/utils';
import cn from 'classnames';
import Image from 'next/image';
import Link from 'next/link';
import React from 'react';
import type {ArticleType} from '../../@types/article';
import {ArticleProps} from './article';

type ArticleImageProps = {
  article: ArticleType;
  variant: ArticleProps['variant'];
  priority?: boolean;
};

function getImageSize(variant: ArticleProps['variant']) {
  // sizes="(max-width: 500px) 100px, (max-width: 1023px) 400px, 1000px"
  switch (variant) {
    case 'horizontal':
      return '150px';
    case 'large':
      return '720px';
    case 'small':
      return '50px';
    case 'main':
      return '700px';
    default:
      return '300px';
  }
}

export function ArticleImage({article, variant, priority}: ArticleImageProps) {
  const isHorizontal = variant === 'horizontal' || variant === 'small';
  const image = article?.image?.url ?? article.companies?.[0]?.logo?.url;

  if (!image) {
    return null;
  }

  return (
    <Link href={`/nyheter/${article.slug}`}>
      <a>
        <div
          className={cn(
            'rounded-lg overflow-hidden',
            article.image ? '' : 'p-2 border bg-white',
            isHorizontal && 'ml-6'
          )}
        >
          <div
            className={cn(
              'relative block pb-[56.25%] h-auto',
              variant === 'small'
                ? 'w-12 h-auto'
                : variant === 'main'
                ? 'pb-[60.25%]'
                : isHorizontal
                ? 'w-24 md:w-32 pb-[70%] '
                : 'w-full'
            )}
          >
            <Image
              src={getImageUrl(image)}
              alt={article.title?.toLowerCase()}
              layout="fill"
              objectFit={article.image ? 'cover' : 'contain'}
              sizes={getImageSize(variant)}
              priority={!!priority}
            />
            {article.sponsored && (
              <span className="absolute bottom-0 right-0 px-1 text-[11px] bg-white/60 rounded-tl-sm dark text-background">
                Annons
              </span>
            )}
          </div>
        </div>
      </a>
    </Link>
  );
}
