import Head from 'next/head';
import {useRouter} from 'next/router';

import {SITE_META} from '@/lib/config';
import {trimLength} from '@/lib/utils';

import {CookieBanner} from './cookie-banner';

type LayoutProps = {
  title?: string;
  description?: string;
  image?: string;
  date?: string;
  children: React.ReactNode;
  className?: string;
};

export function Layout(props: LayoutProps) {
  const router = useRouter();
  const {title, children, ...customMeta} = props;
  const meta = {...SITE_META, title, ...customMeta};
  const siteUrl =
    process.env.NODE_ENV === 'production' ? 'https://ipo.se' : 'http://localhost:3000';

  return (
    <>
      <Head>
        <meta charSet="UTF-8" />
        <title>{title ? `${title} - IPO.se` : meta.title}</title>
        {/* TODO: Remove noindex and comment when ready to go live */}
        <meta name="robots" content="noindex" />
        {/* <meta name="robots" content="follow, index" /> */}
        <meta content={meta.description} name="description" />
        <meta property="og:url" content={`${siteUrl}${router?.asPath}`} />
        <meta name="viewport" content="width=device-width, initial-scale=1" />
        <link rel="canonical" href={`${siteUrl}${router?.asPath}`} />
        <meta property="og:type" content={meta.type} />
        <meta property="og:site_name" content={meta.name} />
        <meta property="og:description" content={trimLength(meta.description || '', 160)} />
        <meta property="og:title" content={meta.title} />
        <meta property="og:image" content={meta.image} />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:site" content={meta.twitter} />
        <meta name="twitter:title" content={meta.title} />
        <meta name="twitter:description" content={meta.description} />
        <meta name="twitter:image" content={meta.image} />
        {meta.date && <meta property="article:published_time" content={meta.date} />}
      </Head>

      <div
        className={`min-h-screen pt-[var(--header-height-mobile)] md:pt-[var(--header-height)] ${
          props.className ? props.className : ''
        }`}
      >
        {children}
      </div>
      <CookieBanner />
    </>
  );
}
